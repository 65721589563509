import React from "react"
import { PageLayout } from "../components/layout"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"

const TagItem = ({ children }) => {
  const colors = [
    "text-white bg-red-400 hover:bg-red-500",
    "text-white bg-yellow-400 hover:bg-yellow-500",
    "text-white bg-purple-400 hover:bg-purple-500",
    "text-white bg-teal-400 hover:bg-teal-500",
  ]


  const className = `inline-block rounded-full duration-300 text-sm \
    font-semibold px-2 md:px-4 py-1 opacity-90 hover:opacity-100 \
    ${colors[Math.floor(Math.random() * colors.length)]}`
  return (
    <span className={className}>
      {children}
    </span>
  )
}

const TagsPage = ({ data: { allMdx: { group } } }) => {
  return (
    <PageLayout>
      <Seo title="标签" />
      <div className="main card">
        <p className="text-title">所有标签</p>
        <div className="flex flex-wrap space-x-2">
          {group.map(({ fieldValue }) => (
            <Link to={`/tags/${fieldValue}`}>
              <TagItem >{fieldValue}</TagItem>
            </Link>
          ))}
        </div>
      </div>
    </PageLayout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  {
    allMdx(filter: {slug: {regex: "/posts/"}, frontmatter: {draft: {ne: true}}}) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`